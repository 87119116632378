<template>
  <section class="containerResultsIntelligence">
    <resultsPage :config ="configuration" ref="resultsPageRef">
    </resultsPage>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import resultsPage from '@/components/resultsPage/resultsPage'
import configurationFile from './resultsIntelligence.json'
import store from '../../store/index'

export default {
  name: 'resultsIntelligence',
  components: {
    resultsPage
  },
  beforeRouteEnter () {
    store.commit({
      type: 'CHANGE_DEFAULT_FLOW',
      defaultSource: 'intelligence'
    })
  },
  setup (props) {
    const store = useStore()
    const itemId = ref(null)
    const resultsPageRef = ref(null)

    /**
     * @description Add isUploadButtonVisible atribute to the {configuration} object.
     */
    const configuration = computed(() => {
      return Object.assign(configurationFile.config, { isUploadButtonVisible: store.getters.actionCanBePerformed('create', 'IntelItem') && !store.state.isMobile })
    })

    return {
      store,
      itemId,
      resultsPageRef,
      configuration
    }
  }
}
</script>
